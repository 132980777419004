@import 'styles/base.scss';

.GuestsSelector {
  .GuestsSelectorTitle {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .RadioButtons {
    display: flex;

    .Radiobutton {
      margin-right: 50px;

      label {
        font-weight: 600;
        color: $ink;
      }
    }
  }

  .CheckboxGuestsSelector {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;

    input {
      opacity: 0;
      z-index: -1;
      position: absolute;

      + label {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .box {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #979797;
          border-radius: 2px;
          height: 24px;
          width: 24px;
          overflow: hidden;
          background-color: $white-100;
        }
      }

      &:checked {
        + label {
          svg {
            opacity: 1;
          }

          .box {
            background-color: $darkblue-100;
            border: none;
          }
        }
      }
    }

    svg {
      opacity: 0;
      height: auto;
      width: 12px;
      fill: $light;
    }

    span {
      display: block;
      margin-left: $unit-300;
      font-size: 14px;
    }

    &.is-partial {
      input {
        + label {
          svg {
            opacity: 1;
            fill: $darkblue-100;
          }

          .box {
            background-color: transparent;
            border: 1px solid $darkblue-100;
          }
        }
      }
    }

    &.is-readonly {
      opacity: 0.5;

      input {
        + label {
          cursor: not-allowed;
        }
      }
    }
  }

  .GuestListsSelect {
    display: flex;
    flex-wrap: wrap;

    .CheckboxGuestsSelector {
      padding: $unit-300;
      margin-right: $unit-300;
      margin-bottom: $unit-300;
      border: 1px solid #dedede;
      border-radius: 4px;

      &.is-checked {
        background: rgba(41, 122, 209, 0.07);
        border: 1px solid #96b3d3;
      }

      span {
        font-weight: 600;
        color: $ink;
      }
    }
  }

  .Error {
    color: $warning;
  }
}

.GuestListSection {
  margin-top: $unit-600;
  padding-top: $unit-300;
  border-top: 1px solid #e1e2e3;
}

.GuestsListToggle {
  display: flex;
  flex-direction: row;
  align-items: center;

  border: none;
  outline: none;

  font-weight: 600;
  color: $darkblue-100;

  cursor: pointer;

  svg + span {
    margin-left: $unit-200;
  }
}

.GuestsListContainer {
  margin-top: $unit-400;
  padding: $unit-400 $unit-700 $unit-400 $unit-400;
  border: 1px solid #dedede;
  border-radius: 4px;

  .Search {
    width: 100%;
  }
}

.GuestListSubtitle {
  margin-top: 26px;
  margin-bottom: $unit-300;
}

.GuestsListItem {
  margin-bottom: $unit-300;
}
