// Base Color file
// Project: Cetrez Events

// Root color definitions
$ink: #151515;
$light: #ffffff;

// Shade construction
$blue: #4499f5;

$blue-100: lighten($blue, 16);
$blue-200: lighten($blue, 8);
$blue-300: lighten($blue, 4);
$blue-400: $blue;
$blue-500: darken($blue, 4);
$blue-600: darken($blue, 8);
$blue-700: darken($blue, 16);
$blue-800: darken($blue, 24);
$blue-900: darken($blue, 40);

$blue-list: $blue-100, $blue-200, $blue-300, $blue-400, $blue-500, $blue-600,
  $blue-700, $blue-800, $blue-900;

$darkblue: #0d325b;

$darkblue-100: lighten($darkblue, 16);
$darkblue-200: lighten($darkblue, 8);
$darkblue-300: lighten($darkblue, 4);
$darkblue-400: $darkblue;
$darkblue-500: darken($darkblue, 4);
$darkblue-600: darken($darkblue, 8);
$darkblue-700: darken($darkblue, 16);
$darkblue-800: darken($darkblue, 24);
$darkblue-900: darken($darkblue, 40);

$darkblue-list: $darkblue-100, $darkblue-200, $darkblue-300, $darkblue-400,
  $darkblue-500, $darkblue-600, $darkblue-700, $darkblue-800, $darkblue-900;

$red: #f84f6e;

$red-100: lighten($red, 16);
$red-200: lighten($red, 8);
$red-300: lighten($red, 4);
$red-400: $red;
$red-500: darken($red, 4);
$red-600: darken($red, 8);
$red-700: darken($red, 16);
$red-800: darken($red, 24);
$red-900: darken($red, 32);

$red-list: $red-100, $red-200, $red-300, $red-400, $red-500, $red-600, $red-700,
  $red-800, $red-900;

$green: #29c166;

$green-100: lighten($green, 24);
$green-200: lighten($green, 16);
$green-300: lighten($green, 8);
$green-400: $green;
$green-500: darken($green, 8);
$green-600: darken($green, 16);
$green-700: darken($green, 24);
$green-800: darken($green, 28);
$green-900: darken($green, 32);

$green-list: $green-100, $green-200, $green-300, $green-400, $green-500,
  $green-600, $green-700, $green-800, $green-900;

$grey: #8c8f95;

$grey-100: lighten($grey, 32);
$grey-200: lighten($grey, 24);
$grey-300: lighten($grey, 16);
$grey-400: $grey;
$grey-500: darken($grey, 8);
$grey-600: darken($grey, 16);
$grey-700: darken($grey, 24);
$grey-800: darken($grey, 32);
$grey-900: darken($grey, 40);

$grey-list: $grey-100, $grey-200, $grey-300, $grey-400, $grey-500, $grey-600,
  $grey-700, $grey-800, $grey-900;

$white-100: $light;
$white-200: darken($light, 2);
$white-300: darken($light, 6);
$white-400: darken($light, 10);
$white-500: darken($light, 14);
$white-600: darken($light, 18);
$white-700: darken($light, 22);
$white-800: darken($light, 26);
$white-900: darken($light, 28);

$white-list: $white-100, $white-200, $white-300, $white-400, $white-500,
  $white-600, $white-700, $white-800, $white-900;

$purple: #8591b4;

$purple-100: lighten($purple, 34);
$purple-200: lighten($purple, 32);
$purple-300: lighten($purple, 22);
$purple-400: $purple;
$purple-500: darken($purple, 8);
$purple-600: darken($purple, 16);
$purple-700: darken($purple, 24);
$purple-800: darken($purple, 32);
$purple-900: darken($purple, 40);

$purple-list: $purple-100, $purple-200, $purple-300, $purple-400, $purple-500,
  $purple-600, $purple-700, $purple-800, $purple-900;

$orange: #ffa061;

$orange-100: lighten($orange, 32);
$orange-200: lighten($orange, 24);
$orange-300: lighten($orange, 16);
$orange-400: $orange;
$orange-500: darken($orange, 8);
$orange-600: darken($orange, 16);
$orange-700: darken($orange, 24);
$orange-800: darken($orange, 32);
$orange-900: darken($orange, 40);

$orange-list: $orange-100, $orange-200, $orange-300, $orange-400, $orange-500,
  $orange-600, $orange-700, $orange-800, $orange-900;

// Color assignment
$primary: $blue;
$secondary: $darkblue;
$text-color: $ink;

$success: $green;
$warning: $red;
$caution: $orange;
$white: $light;
$black: $ink;

$default: (
  a1: #8591b4,
  a2: #a7b1c7,
  a3: #d6dbe6,
  b1: #285e99,
  b2: #dee5ec,
  b3: #eaeef3,
  b4: #fafafc
);

$colors: (
  primary: #3f90e6,
  primary--disabled: #2e70b7,
  primary--hover: #4499f5,
  secondary: #224b78,
  secondary--hover: #4a6684,
  secondary--bright: #f0f4f8,
  secondary--semi: #284970,
  secondary--dark: #0d325b,
  dark: #082443,
  dark--hover: #0a2a4c,
  toolbar: #547497,
  toolbar--hover: #3a6798,
  warning: #e21042,
  focus: #2297ff,
  success: #29c166,
  success--hover: #3dd67a,
  draft: #ec644b,
  closed: #3c5a7b,
  active: #15b75a,
  full: #ef8a00
);
