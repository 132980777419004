@import 'styles/base.scss';

.Text {
  &[data-icon='true'] {
    position: relative;

    input {
      padding-right: $unit-700;

      &:focus + svg {
        fill: $blue-700;
      }
    }

    svg {
      position: absolute;
      right: $unit-300;
      top: 50%;
      transform: translateY(-50%);
      fill: $purple-400;
      height: 28px;
      width: 28px;
      pointer-events: none;
    }
  }

  &.is-errored {
    input {
      border-color: $warning;
    }
  }

  input {
    background-color: $white-100;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 1px solid $grey-100;
    border-radius: $unit-100 / 2;
    font-size: 14px;
    font-family: inherit;
    outline: none;
    padding: $unit-300 $unit-300;
    max-height: 48px;
    transition: border 0.125s ease-in-out;
    @include te();

    &:focus {
      border: 1px solid $blue-700;
    }

    &[readonly],
    &:read-only {
      background-color: $white-300;
      color: $grey-600;
    }
  }
}
