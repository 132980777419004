@import 'styles/base.scss';

.FieldComponent {
  margin: 0 auto;
  width: calc(100% - 80px);
  padding: $unit-200 0;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;

  &[data-active='true'] {
    border-left-color: $blue-400;
    border-right-color: $blue-400;
    background: $white-200;

    input {
      border-left-color: transparent;
      border-right-color: transparent;
      border-radius: 0;
    }
  }

  .meta {
    width: calc(100% - #{$unit-300});
    margin: $unit-200 auto 0;

    .Checkbox {
      display: flex;

      label {
        flex: 1 0 auto;
        justify-content: flex-start;
      }
    }
  }

  .Textarea {
    margin: 0 0 $unit-200;
  }
}
