@import 'styles/base.scss';

$columnMaxWidth: 426px; // magic number inherited from other parts of the Settings view
$inputHeight: 48px;
$labelSize: 14px;
$labelGutter: $unit-200;
$removeIconLength: 20px;

.TimeSlot {
  position: relative;
}

.TimeSlotRow {
  display: flex;

  & + & {
    margin-top: $unit-300;
  }
}

.TimeSlotColumn {
  max-width: $columnMaxWidth;
  flex: 0 0 auto;

  &:first-child {
    flex: 1 1 auto;
  }

  & + & {
    margin-left: $unit-400;
  }
}

.TimeSlotLabel {
  display: inline-block;
  margin-bottom: $labelGutter;
  font-size: $labelSize;
  font-weight: 500;
}

.TimeSlotDatePicker {
  width: 100%;
  margin-right: 0;
}

.TimeSlotRemove {
  outline: none;
  border: 0;
  padding: 0;
  position: absolute;
  top: $labelSize + $labelGutter + ($inputHeight / 2) - ($removeIconLength / 2);
  right: 0;
  transition-property: opacity, fill;
  transition-duration: $transition-short;
  cursor: pointer;
  fill: $grey-600;

  &:disabled {
    cursor: not-allowed;
  }

  &:enabled {
    &:hover {
      fill: $grey-900;
    }

    &:active:hover {
      transition: none;
      opacity: 0.5;
    }
  }
}
