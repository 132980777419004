@import 'styles/base.scss';

.CreateEventCard {
  margin-bottom: $unit-400;
  width: 266px;

  &:hover {
    .PlusIcon {
      fill: $primary;
    }

    h3.title {
      color: $primary;
    }
  }

  .meta {
    text-align: center;
    display: block;
    padding-top: $unit-400;

    h3.title {
      text-align: center;
    }
  }
}

.CreateEventButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 178px;
  background-color: $light;
  border: 1px dashed $grey-200;
  color: $white-300;
  width: 100%;
  border-radius: 4px;
  cursor: pointer;
}
