@import 'styles/base.scss';

$inputSize: 42px;

.TimePicker {
  position: relative;
  flex: 1 1 auto;
  outline: none;

  &.is-readonly {
    opacity: 0.6;
    pointer-events: none;
  }

  &.is-errored {
    .PickerButton,
    .icon {
      border-color: $warning;
    }
  }
}

.SelectableTime {
  top: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: $unit-200 auto 0;
  z-index: 1;
  border: 1px solid $grey-100;
  border-radius: 6px;
  width: 100%;
  max-width: 124px;
  height: 311px;

  overflow-y: scroll;
  background-color: #fff;
  z-index: 2;

  .time {
    display: block;
    width: 100%;
    padding: $unit-300;
    border: 0px;
    border-bottom: 1px solid $grey-100;
    text-align: center;
    cursor: pointer;

    &:last-child {
      border-bottom: 0px;
    }

    &:hover {
      background: $white-300;
      color: $grey-700;
    }

    &:disabled {
      color: $grey-200;

      &:hover {
        background: $white-100;
      }
    }

    &:focus {
      outline: none;
      background: $grey-100;
      color: $grey-700;
    }

    &[data-active='true'] {
      background-color: $primary;
      color: $white-100;

      &:hover {
        background-color: $primary;
        color: $white-100;
      }

      &:focus {
        outline: none;
        background: $blue-700;
        color: $white;
      }
    }
  }
}
