@import 'styles/base.scss';

.EventSettings {
  $menu-width: 300px;
  height: calc(100vh - #{$editorHeaderHeight});
  background: $white;
  padding-left: calc(50vw - ((#{$container-width} - #{$unit-600} * 2) / 2));
  display: flex;
  overflow: hidden;

  // Transitions
  .slideUp-enter {
    opacity: 0;
    transform: translateY($unit-300);
    z-index: 1;
    position: absolute;
    width: calc(100% - #{$unit-700} - #{$unit-300}) !important;
  }

  .slideUp-enter.slideUp-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity $transition-normal ease-in $transition-normal,
      transform $transition-normal ease-in $transition-normal;
  }

  .slideUp-exit {
    transform: translateY(0);
    opacity: 1;
  }

  .slideUp-exit.slideUp-exit-active {
    transform: translateY($unit-300);
    opacity: 0;
    transition: opacity $transition-normal ease-in,
      transform $transition-normal ease-in;
  }

  // Styles
  .Sidebar {
    $offset: calc(50vw - #{$container-width} / 2 + 64px + #{$menu-width});
    background: $white;

    display: flex;
    justify-content: flex-end;

    .HorizontalMenu {
      width: $menu-width;
      border-right: 1px solid $grey-100;
      padding-top: $unit-600;

      .MenuItem {
        display: block;
        padding: $unit-300 $unit-400 $unit-300 $unit-200;
        position: relative;
        text-decoration: none;
        color: inherit;
        overflow-x: hidden;

        &::after {
          display: block;
          content: '';
          height: 100%;
          background: $darkblue;
          width: $unit-100;
          position: absolute;
          top: 0;
          opacity: 0;
          transform: translateX($unit-100);
          right: 0;
          transition: transform 0.25s ease-out, opacity 0.25s ease-out;
        }

        &.active {
          color: $darkblue;
          font-weight: 600;
          &::after {
            transform: translateX(0);
            opacity: 1;
            transition: transform 0.25s ease-out 0.25s,
              opacity 0.25s ease-out 0.25s;
          }
        }
      }
    }
  }

  .SettingsContainer {
    background: $white-200;
    flex: 1 0 auto;
    overflow-y: auto;

    .track {
      max-width: calc(#{$container-width} - #{$menu-width} - #{$unit-200} * 11);
      min-height: calc(100vh - #{$editorHeaderHeight});
      height: auto;

      padding: $unit-600;
      padding-right: 0;
      position: relative;

      > .item {
        background: white;
        padding: $unit-400;
        border-radius: 4px;
      }
    }
  }
}
