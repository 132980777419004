@import 'styles/base.scss';

$separatorWidth: 16px;
$separatorHeight: 16px;

.EventDetail {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: white;
}

.EventDetailNavSeparator {
  width: $separatorWidth;
  height: $separatorHeight;
  position: absolute;
  top: 50%;
  left: 0;
  margin-left: -$separatorWidth / 2;
  margin-top: -$separatorHeight / 2;
  fill: rgba(white, 0.25);
}

.EventDetailNavBar {
  background-color: #0a2746;
  color: white;
}

.EventDetailNav {
  height: 44px;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  transition: opacity $transition-short;

  &.Nav .NavItem a {
    color: rgba(white, 0.6);
    padding-top: $unit-100;
    padding-bottom: $unit-100;
    font-size: 12px;
    border-bottom: 2px solid transparent;
    top: 1px;
    position: relative;
    transition-property: color, border-color;
    transition-duration: $transition-short;

    &:hover,
    &.active {
      color: rgba(white, 1);
    }

    &.active {
      border-bottom-color: currentColor;
    }
  }

  &.isLoading {
    opacity: 0;
  }
}

.EventDetailTitle {
  flex: 1 1 auto;
  font-weight: 600;
  letter-spacing: 0.5px;
  max-width: 100%;
  padding-right: $unit-600;
  cursor: default;
  @include te;
}

.EventDetailTime {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 14px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  color: rgba(white, 0.5);
}

.EventDetailContain {
  display: flex;
  position: relative;
}
