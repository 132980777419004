@import 'styles/base.scss';

.InfoBar {
  padding: 1em;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 600;
  border: 1px solid #c2cadb;
  background-color: map-get($colors, secondary--bright);
  color: map-get($colors, toolbar);
  position: relative;
}
