@import 'styles/base.scss';

$dateSize: 32px;

.Days {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .day {
    border: 0;
    border-radius: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: $dateSize;
    width: $dateSize;
    margin: $unit-100 $unit-200;
    outline: none;
    cursor: pointer;
    background-color: $white-100;

    &:disabled {
      cursor: default;
    }

    &:enabled {
      &:hover {
        background-color: $white-300;
        color: $grey-700;
      }

      &:active {
        background-color: $primary;
        color: $white-100;
      }
    }

    &[data-different-month='true'],
    &:disabled {
      color: $white-500;
    }

    &:enabled,
    &:disabled {
      &[data-active='true'] {
        background-color: $primary;
        color: $white-100;
      }
    }
  }
}
