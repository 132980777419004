@import 'styles/base.scss';

.Months {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto $unit-200;
  padding: $unit-300;
}

.MonthsLabel {
  display: inline-block;
  width: 160px;
  text-align: center;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
}

.ArrowIcon {
  border: 0px;
  background-color: transparent;
  padding: 0px;
  height: 14px;
  cursor: pointer;
  fill: $purple-400;

  svg {
    height: 14px;
    width: auto;
  }

  &[data-direction='left'] {
    transform: rotate(180deg);
  }
}
