@import 'styles/base.scss';

.AccordionTable {
  &[data-debug='true'] {
    width: 100%;
    margin: 50px;
  }
}

.ResultsEmptyState {
  padding: $unit-300 $unit-400;
  border: 1px dashed map-get($default, a2);
  border-radius: 4px;
  text-align: center;
}

.NoResultsText {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: map-get($colors, secondary);

  .EventMark {
    font-variant-caps: all-small-caps;
  }
}
