@import 'styles/base.scss';

$borderWidth: 1px;

.TableHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: ($unit-300 - $borderWidth) $unit-400;
  background-color: map-get($default, b4);
  color: $darkblue-100;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  border: $borderWidth solid $grey-200;
  border-radius: $unit-100 $unit-100 0 0;

  .label {
    @include te;
    padding-right: $unit-300;

    &[title] {
      cursor: help;
    }
  }

  .left,
  .right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .left {
    width: calc(100% - 340px);

    > div {
      flex: 1 1 100%;
    }
  }

  .right {
    width: 340px;

    .date {
      width: 170px;
    }

    .checkin {
      width: 90px;
    }

    .guests {
      width: 80px;
      text-align: right;
    }
  }
}
