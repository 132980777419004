@import 'styles/base.scss';

$statusHeight: 20px;
$statusBorder: 3px;
$titleSize: 16px;
$titleLineHeight: 1.2;
$titleRowHeight: $titleSize * $titleLineHeight;
$visibleRows: 2;
$deletedInset: $unit-200;

.EventCard {
  color: inherit;
  text-decoration: none;
  display: block;

  &:hover {
    .eventThumbnail {
      @include bs-large($black); // Box shadow
    }

    .title {
      color: $blue-600;
    }

    .EventCardStatus {
      color: rgba(white, 1);
    }
  }

  &:not(:hover) {
    .EventCardStatus {
      transform: translateY(#{$statusHeight - $statusBorder});
    }
  }

  .EventCardStatus {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 20px;
    padding: $statusBorder $unit-100;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    color: rgba(white, 0);
    transition-property: transform, color;
    transition-duration: $transition-short;
  }

  .eventThumbnail {
    @include bs($black); // Box shadow
    overflow: hidden;
    box-sizing: border-box;

    &:not(.hasSummary) {
      border-radius: $unit-100;
    }

    &.hasSummary {
      border-top-right-radius: $unit-100;
      border-top-left-radius: $unit-100;
    }
  }

  .eventThumbnailInner {
    position: relative;
  }

  .meta {
    text-align: center;
    display: block;
    padding-top: $unit-300;

    .date {
      margin-top: $unit-200;
      opacity: 0.5;
    }
  }

  // Summary
  .EventCardSummary {
    border-bottom-right-radius: $unit-100;
    border-bottom-left-radius: $unit-100;
    border: 1px solid $grey-100;
    border-top: 0px;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $unit-300 $unit-500;
    background-color: white;
    position: relative;

    > div {
      padding: 0 $unit-200;
      flex: 1 1 100%;
    }

    img {
      display: block;
      height: 23px;
      width: auto;
      margin: 0 auto $unit-200;
    }

    svg {
      display: block;
      margin: 0 auto $unit-200;
    }

    span {
      display: block;
      text-align: center;
      max-width: 50px;
      margin: 0 auto;
      @include te; // Text ellipsis
    }
  }

  .title {
    font-size: $titleSize;
    line-height: $titleLineHeight;
    max-height: $titleRowHeight * $visibleRows;
    overflow: hidden;
    position: relative;
    word-break: break-word;
    transition: color $transition-short;

    &::after {
      content: '';
      display: block;
      height: $titleRowHeight;
      width: 75%;
      position: absolute;
      right: 0;
      top: $titleRowHeight * ($visibleRows - 1);
      pointer-events: none;
      background-image: linear-gradient(
        to right,
        rgba($white-200, 0),
        rgba($white-200, 1)
      );
    }
  }
}

.EventCardDeleted {
  position: absolute;
  bottom: $deletedInset;
  left: $deletedInset;
  right: $deletedInset;
}

.EventCardButton {
  border: none;
  cursor: pointer;
  padding: 0;
  width: 100%;
}
