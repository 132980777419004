// Base Mixin file
// Project: Cetrez Events

// Media Queries
// @mixin mq
@mixin mq($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

// Box shadows
// @mixin bs
@mixin bs($shadowColor) {
  box-shadow: 0 3px 5px 0 rgba($shadowColor, 0.05);
}

@mixin bs-large($shadowColor) {
  box-shadow: 0 3px 10px 0 rgba($shadowColor, 0.1);
}

// Text ellipsis
// @mixin te
@mixin te {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Get from list
@mixin get-background-color-list($list, $index) {
  background: nth($list, $index);
}

@mixin forceRatio($ratio: 1 1) {
  &::before {
    content: '';
    display: block;
    padding-bottom: percentage(nth($ratio, 2) / nth($ratio, 1));
  }
}
