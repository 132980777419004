@import 'styles/base.scss';

.OverviewEvents {
  min-width: 100%;

  strong {
    font-weight: 700;
  }
}

.OverviewEvents,
.OverviewWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.OverviewWrapper {
  padding-top: $unit-600;
  padding-bottom: $unit-600;
}

.OverviewEventsGrid {
  & + & {
    margin-top: $unit-700;
    padding-top: $unit-700;
    border-top: 1px solid #dee5ec;
  }
}

.OverviewEventsHeading {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
}

.OverviewEventsCount {
  opacity: 0.5;
}

.OverviewEventsTable {
  margin-top: $unit-600;
}

.OverviewEventsInfo {
  margin-top: $unit-300;
}

.OverviewEventsInfoIcon {
  width: 22px;
  height: 22px;
  margin-right: $unit-200;
  font-size: 14px;
  font-weight: 700;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: map-get($colors, secondary--semi);
  color: white;
  border-radius: 50%;
}
