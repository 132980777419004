@import 'styles/base.scss';

$circleLength: 48px;

.EditExtendedLandingPagesModal {
  .Header {
    padding-bottom: $unit-200;
  }

  .Nav {
    display: flex;
    background-color: white;
    margin-bottom: $unit-300;
    border-bottom: 1px solid map-get($default, b2);

    .NavItem {
      display: flex;
      justify-content: center;
      position: relative;

      &:not(:first-child) {
        padding-left: 18px;
      }

      &:not(:last-child) {
        padding-right: 18px;
      }
    }

    // nav link styling
    button {
      display: inline-flex;
      align-items: center;
      position: relative;
      color: $grey;
      text-decoration: none;
      font-size: 13px;
      transition: opacity $transition-normal ease-in-out;
      padding: $unit-300 0;
      border: 0;
      cursor: pointer;

      &::after {
        content: '';
        display: block;
        background-color: currentColor;
        height: 2px;
        width: 0;
        position: absolute;
        bottom: 0;
        transition: width $transition-normal ease-in-out $delay-short;
      }

      &.active {
        color: $blue;

        &::after {
          width: 100%;
        }
      }
    }
  }

  .FileInputDescription {
    margin-bottom: $unit-400;
  }

  .EditExtendedLandingPagesModalError {
    margin-top: $unit-200;
    color: $red;
  }
}
