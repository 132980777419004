@import 'styles/base.scss';

.UserSelect {
  display: flex;
  align-items: center;
}

.UserSelectName {
  text-transform: uppercase;
  margin-right: $unit-300;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.12em;
  color: $darkblue-400;
  cursor: default;
}

.UserSelectButton {
  flex: 0 0 auto;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 0;
  background-color: $darkblue-400;
  border-radius: 4px;
  color: white;
  transition-property: background-color, color;
  transition-duration: $transition-short;

  &:active:hover {
    transition: none;
    color: rgba(white, 0.5);
    background-color: $darkblue-500;
  }
}
