@import 'styles/base.scss';

$columnMaxWidth: 426px; // magic number inherited from other parts of the Settings view

.TimeSlotsList {
  margin-bottom: $unit-400;
}

.TimeSlotsItem {
  & + & {
    margin-top: $unit-500;
    padding-top: $unit-500;
    border-top: 1px solid $grey-100;
  }
}

.TimeSlotsInner {
  position: relative;
}

.TimeSlotsRow {
  display: flex;

  & + & {
    margin-top: $unit-300;
  }
}

.TimeSlotsColumn {
  max-width: $columnMaxWidth;
  flex: 0 0 auto;

  &:first-child {
    flex: 1 1 auto;
  }

  & + & {
    margin-left: $unit-400;
  }
}

.TimeSlotsLabel {
  display: inline-block;
  margin-bottom: $unit-200;
  font-size: 14px;
  font-weight: 500;
}

.TimeSlotsDatePicker {
  width: 100%;
  margin-right: 0;
}

.TimeSlotsRemove {
  outline: none;
  border: 0;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 2rem;
  transition: opacity $transition-short;
  cursor: pointer;

  &:active:hover {
    transition: none;
    opacity: 0.5;
  }
}
