// Base Unit file
// Project: Cetrez Events

// Root Unit (all padding / margin is generated from this)
$unit: 8px;

// Unit definition
$unit-100: $unit / 2; // 4px
$unit-200: $unit; // 8px
$unit-300: $unit * 2; // 16px
$unit-400: $unit * 3; // 24px
$unit-500: $unit * 4; // 32px
$unit-600: $unit * 5; // 40px
$unit-700: $unit * 8; // 64px
$unit-800: $unit * 12; // 96px
$unit-900: $unit * 20; // 160px

// Container unit
$container-width: 1280px;

$editorHeaderHeight: 120px;
