@import 'styles/base.scss';

// local variables:
// ----------------

$dotLength: 8px;
$duration: 600ms;

@keyframes LoadingDotsPulse {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.333;
  }
}

// ----------------

.LoadingDots {
  display: flex;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
  margin-top: -$dotLength / 2;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}

.LoadingDot {
  animation: LoadingDotsPulse $duration infinite alternate both;

  &:nth-child(2) {
    animation-delay: $duration * 1 / 3;
  }
  &:nth-child(3) {
    animation-delay: $duration * 2 / 3;
  }
}
