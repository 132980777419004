@import 'styles/base.scss';

.SmsDashboard {
  .Description {
    max-width: 60ch;
    margin-top: $unit-200;
    margin-bottom: 30px;
  }

  .ButtonText {
    margin-left: $unit-200;
  }

  .SmsTable {
    margin-top: 40px;
  }

  .NoPhone {
    display: inline-block;
    margin-bottom: 30px;
    padding: 20px;
    background-color: $light;
    border: 1px solid $primary;
    border-radius: 8px;
  }

  .NoPhoneLink {
    font-size: 14px;
    color: $primary;
  }

  .NewButton {
    &[disabled] {
      opacity: 0.5;
    }
  }
}
