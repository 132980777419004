@import 'styles/base.scss';

.Nav {
  display: flex;

  .NavItem {
    display: flex;
    justify-content: center;
    position: relative;

    &:not(:first-child) {
      padding-left: $unit-300;
    }

    &:not(:last-child) {
      padding-right: $unit-300;
    }

    a {
      display: inline-flex;
      align-items: center;
      position: relative;
      color: inherit;
      text-decoration: none;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      transition: color $transition-short;

      &[data-disabled='true'] {
        opacity: 0.2;
        cursor: default;
      }
    }
  }
}
