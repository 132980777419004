@import 'styles/base.scss';

$inset: $unit-600;

[data-modal='CreateEventModal'] {
  .DatePicker {
    width: auto;
  }

  .TimePicker {
    width: auto;
  }
}

.TemplatesContainer {
  display: flex;
  flex-direction: column;
  max-height: 310px;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 (-$inset) (-$inset);
  padding: 0 $inset;

  .Template {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 126px;
    padding: 0 $unit-400;
    border: 1px solid $grey-100;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;

    & + .Template {
      margin-top: $unit-300;
    }

    &[data-chosen='true'] {
      background-color: $white-200;
      border-color: $primary;
    }

    .meta {
      overflow: hidden;
    }

    .Thumbnail {
      height: calc(126px - (#{$unit-400} * 2));
      min-width: calc(126px - #{$unit-400});
      max-width: calc(126px - #{$unit-400});
      border: 1px solid $white-300;
      margin-right: $unit-500;
    }

    h3 {
      font-weight: 600;
      width: auto;

      @include te();
    }

    .tags {
      display: flex;
      margin-top: $unit-300;

      .tag {
        margin-right: $unit-200;
        background-color: $purple-100;
        color: $darkblue-100;
        font-size: 14px;
        font-weight: 500;
        border-radius: 4px;
        padding: $unit-100 $unit-200;
      }
    }
  }
}

.TemplatesContainerInner {
  padding-bottom: $inset;
}
