@import 'styles/base.scss';

.Creator {
  background-color: $white-100;
  border: 1px solid map-get($default, a3);
  box-shadow: 3px 3px 43px rgba(21, 21, 21, 0.17);
  border-radius: 5px;
  padding: $unit-300;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  right: 0;
  width: calc(100% - #{$unit-400});
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  z-index: 2;

  &[data-debug='true'] {
    top: initial;
    transform: none;
    max-width: 478px;
    position: relative;
  }

  button {
    width: calc(100% / 3);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 0;
    border: 1px solid transparent;
    padding: $unit-200;
    background: none;
    cursor: pointer;
    text-align: left;
    color: $purple-400;
    font-weight: 600;
    font-size: 12px;

    &:hover {
      border-color: $blue-700;
      color: $blue-700;

      svg {
        fill: $blue-700;
      }
    }

    svg {
      height: 24px;
      width: 24px;
      margin-right: $unit-200;
      fill: $purple-400;
    }
  }
}
