@import 'styles/base.scss';

.Wrapper {
  max-width: 1280px;
  min-width: 1280px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 48px;
  padding-right: 48px;

  &[data-flex='true'] {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}
