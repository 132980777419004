@import 'styles/base.scss';

html,
body {
  background-color: $white-200;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

body {
  margin: 0;
  font-family: $font-primary;
  position: relative;
  overflow-x: auto;
  overflow-y: scroll;
  min-height: 100%;
  display: flex;
}

#root {
  display: flex;
  flex: 1 1 auto;
  min-width: 1280px;
  max-width: 100%;
}

:focus {
  outline: none;
}

[data-input-type='keyboard'] {
  :not(input):not(textarea):not([tabindex='-1']):focus {
    box-shadow: 0 0 0 3px map-get($colors, focus);
  }
}
