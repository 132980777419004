@import 'styles/base.scss';

.DraggableItem {
  margin-bottom: $unit-200;

  &:hover,
  &[data-active='true'] {
    .DraggableHandle {
      opacity: 1;
      transition: opacity $transition-short ease-out $delay-short;
    }

    .FieldComponent {
      background: $white-200;
      border-left-color: $blue-400;
      border-right-color: $blue-400;
      transition: border-left-color $transition-short ease-out $delay-short,
        border-right-color $transition-short ease-out $delay-short;

      input {
        border-left-color: transparent;
        border-right-color: transparent;
        transition: border-left $transition-short ease-out $delay-short,
          border-right $transition-short ease-out $delay-short;
      }
    }
  }

  &[data-dragging='true'] {
    .DraggableHandle {
      opacity: 1;
    }

    .FieldComponent {
      background: $white-200;
      border-left-color: $blue-400;
      border-right-color: $blue-400;

      input {
        border-left-color: transparent;
        border-right-color: transparent;
      }
    }
  }

  .container {
    display: flex;
    // align-items: center;

    flex-direction: row;
  }

  .DraggableHandle {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    width: 40px;
    height: 46px;
    margin-top: $unit-200;
    background-color: $blue-400;

    &[data-delete='true'] {
      cursor: pointer;
    }

    svg {
      fill: $white-100;
    }
  }

  &[data-debug='true'] {
    min-width: 480px;
  }
}
