@import 'styles/base.scss';

.Checkbox {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;

  input {
    opacity: 0;
    z-index: -1;
    position: absolute;

    + label {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .box {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $purple-400;
        border-radius: 4px;
        height: 16px;
        width: 16px;
        overflow: hidden;
        background-color: $white-100;
      }
    }

    &:checked {
      + label svg {
        opacity: 1;
      }
    }
  }

  svg {
    opacity: 0;
    height: auto;
    width: 10px;
    fill: $blue-400;
  }

  span {
    display: block;
    margin-left: $unit-200;
    font-size: 12px;
  }
}
