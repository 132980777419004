@import 'styles/base.scss';

.EventInformationToggle {
  display: flex;
  align-items: center;
  margin-top: $unit-600;

  b {
    font-weight: 700;
    margin: 0 $unit-200 0 $unit-400;
  }
}

.EmailSettings,
.CalendarSettings {
  .SettingsModuleHeader {
    padding-top: 0;
    padding-bottom: 0;
  }
}

#qr_ticket_enabled_container {
  .SettingsModuleHeaderInnerContainer {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

#bcc_container {
  .SettingsModuleHeaderInnerContainer {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.BCCRow {
  display: flex;

  & + & {
    margin-top: $unit-400;
  }
}

.BCCColumn {
  width: 50%;

  &:first-child {
    padding-right: $unit-400 / 2;
  }

  &:last-child:not(:only-child) {
    padding-left: $unit-400 / 2;
  }
}

.StatusIcon {
  width: 5%;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  margin-top: $unit-400;
  margin-left: $unit-200;
}

.BCCErrorContainer {
  color: #151515;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
  border: 1px solid #fe2f1f;
  background: rgba(254, 47, 31, 0.2);

  display: flex;
  padding: 16px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}
