@import 'styles/base.scss';

.ErrorPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex: 1 1 auto;
  min-width: 100%;
}

.ErrorPageBody {
  max-width: 320px;
  width: 100%;
  padding: $unit-700 $unit-500;
  background-color: white;
  box-shadow: 0 0 1.5rem rgba(black, 0.1);
  text-align: center;
  border-radius: $unit-100;
}

.ErrorPageHeading {
  font-size: 5em;
  margin-bottom: $unit-400;
  color: map-get($colors, primary);
}

.ErrorPageDescription {
  color: $text-color;
}
