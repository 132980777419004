@import 'styles/base.scss';

.StatusIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.1;
  text-align: center;
  color: white;
  background-color: map-get($colors, primary);

  &[data-state='DRAFT'] {
    background-color: map-get($colors, draft);
  }

  &[data-state='CLOSED'] {
    background-color: map-get($colors, closed);
  }

  &[data-state='ACTIVE'] {
    background-color: map-get($colors, active);
  }

  &[data-state='FULL'] {
    background-color: map-get($colors, full);
  }
}
