@import 'styles/base.scss';

$circleLength: 48px;

.FormFactory {
  background: $white;
  border: 1px solid $white-400;
  border-radius: $unit-100 / 2;
  margin-bottom: $unit-400;
  min-width: 450px;

  .header {
    padding: $unit-600;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 18px;
    }
  }

  .footer {
    position: relative;
    padding: 0 $unit-600 $unit-600;
  }

  .body {
    margin-top: -$unit-100;
  }
}

.CreatorButton {
  position: relative;
  margin-bottom: -$circleLength / 2;

  &:before {
    content: '';
    display: block;
    background: $grey-100;
    position: absolute;
    top: 50%;
    width: calc(50% - 40px);
    height: 1px;
  }

  &:after {
    content: '';
    display: block;
    background: $grey-100;
    position: absolute;
    right: 0;
    top: 50%;
    width: calc(50% - 40px);
    height: 1px;
  }

  button {
    background-color: $green-400;
    border-radius: 50%;
    height: $circleLength;
    width: $circleLength;
    border: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;

    &:hover {
      background-color: $green-300;
    }
  }

  svg {
    height: 16px;
    width: auto;
    fill: $white-100;
  }
}
