@import 'styles/base.scss';

.Modal {
  &[data-modal='GetLinkModal'] {
    .Header {
      padding-bottom: $unit-300;
    }

    .Content {
      padding-bottom: $unit-500;
    }
  }
}
