@import 'styles/base.scss';

.SortableTable {
  min-width: 100%;
  width: calc(100% + #{$unit-200});
  position: relative;
  left: -$unit-200;

  &.is-empty {
    &,
    .ReactTable,
    .TableEmptyState {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }
  }

  .EventInfo {
    align-items: center;
    display: flex;
    padding-left: $unit-200;

    .meta {
      font-size: 14px;
      min-width: 0;
      line-height: 1.2;
      padding-right: $unit-300;
      flex: 1 1 0px;
    }

    .title {
      font-weight: 500;
      margin-bottom: $unit-100;
      @include te;
    }
  }

  .Dates {
    display: flex;
    align-items: center;
    height: 100%;
    line-height: 1.2;
  }

  .Date {
    display: block;
    color: $grey-500;
    margin: $unit-100 0;

    &:last-child {
      margin-bottom: 0;
    }

    strong {
      font-weight: 500;
      margin-right: $unit-100;
    }
  }

  .StateCell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .StateCellIndicator {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    padding: $unit-100 $unit-200;
    border-radius: 10px;
  }

  .NumberCell {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  // Table overrides
  .rt-tr {
    display: flex;
  }

  .-cursor-pointer {
    cursor: pointer;
  }

  .rt-thead {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    padding-bottom: $unit-300;
    letter-spacing: 0.12em;

    .rt-th {
      outline: none;
      user-select: none;
      opacity: 0.4;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;

      &:first-child {
        padding-left: $unit-200;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        text-align: center;
      }

      $size: $unit-100;

      > div {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 100%;
          top: 50%;
          margin-top: -($size / 2);
          margin-left: $unit-200;
          display: block;
          width: 0;
          height: 0;
          border-left: $size solid transparent;
          border-right: $size solid transparent;
          opacity: 0;
          transition: opacity 0.25s ease-out, transform 0.25s ease-out;
        }
      }

      // Sort states
      &.-sort-desc,
      &.-sort-asc {
        font-weight: 600;
        color: $darkblue-400;
        opacity: 1;

        > div {
          &::after {
            opacity: 1;
            transform: translateY(0);
            transition: opacity 0.25s ease-out, transform 0.25s ease-out;
          }
        }

        &.-sort-desc {
          > div {
            &::after {
              border-bottom: $size solid $darkblue;
            }
          }
        }

        &.-sort-asc {
          > div {
            &::after {
              border-top: $size solid $darkblue;
            }
          }
        }
      }

      &:nth-child(n + 3) {
        justify-content: center;
      }
    }
  }

  .rt-tbody {
    .rt-tr {
      padding: $unit-300 0;
      border-radius: $unit-100;

      &:not(.is-deleted):hover {
        background-color: $purple-100;
        cursor: pointer;
      }

      &.is-deleted {
        background-image: linear-gradient(
          to right,
          rgba(#d71c4c, 0.15),
          rgba(#d71c4c, 0)
        );
      }
    }
  }

  .TableEmptyState {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: $unit-400;
    padding-bottom: $unit-400 + 100px;

    .NothingHereText {
      margin-top: $unit-300;
      font-weight: 600;
      color: $purple-500;
    }
  }

  .Loader {
    margin: $unit-600 auto $unit-100;
  }
}

.EventInfoDeleteLabel {
  margin: $unit-100 0;
  color: #d71d4c;
  font-weight: 500;
}

.EventInfoThumbnail {
  margin-right: $unit-300;
  @include bs($black);
  border-radius: $unit-100;
}
