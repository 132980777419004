@import 'styles/base.scss';

[data-modal='CopyEmailsModal'] {
  .Header {
    padding-bottom: $unit-300;
  }

  .Content {
    p {
      padding-bottom: $unit-400;
      font-size: 14px;
      line-height: 1.5;
    }

    .inputs {
      padding-top: $unit-400;
      border-top: 1px solid $grey-100;
    }
  }
}
