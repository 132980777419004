@import 'styles/base.scss';

.Pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: $unit-500 0;
  width: 100%;

  .PageNumbers {
    max-width: 160px;
    overflow-x: hidden;

    @for $i from 2 through 50 {
      &[data-page='#{$i}'] {
        $size: 32px;
        $margin: $unit-100;

        $offset: ($size + $margin * 2) * ($i - 2);

        .track {
          transform: translateX(-$offset);
          transition: transform $transition-normal ease-out;
        }
      }
    }

    .track {
      display: flex;
      width: 100%;
    }

    .PageNumber {
      color: $grey-400;
      width: 32px;
      min-width: 32px;
      display: flex;
      font-size: 14px;
      font-weight: 600;
      align-items: center;
      justify-content: center;
      height: 32px;
      line-height: 1;
      text-align: center;
      margin: 0 $unit-100;
      border-radius: 32px;
      transition-property: background-color, opacity;
      transition-duration: $transition-normal;

      &:hover {
        background-color: $grey-100;
        cursor: pointer;
        user-select: none;
      }

      &[data-active='true'] {
        background-color: $darkblue-200;
        color: $white-100;
        opacity: 1;
      }
    }
  }

  .ResultCountSelector {
    display: flex;
    flex-direction: row-reverse;
    flex: 1 0 33%;
    position: relative;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid $white-400;
      padding: $unit-300 $unit-500 $unit-300 $unit-300;
      background-color: $white-100;
      font-size: calc(#{$unit-200} + #{$unit-100});
      font-family: $font-primary;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1px;
      color: $darkblue-400;
      cursor: pointer;
    }

    .CheIcon {
      position: absolute;
      right: $unit-300;
      height: 12px;
      width: 12px;
      top: 35%;
    }
  }

  > span {
    flex: 1 0 33%;
  }

  &[data-debug='true'] {
    margin: 50px 30px;
  }
}
