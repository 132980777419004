@import 'styles/_animation.scss';
@import 'styles/_colors.scss';
@import 'styles/_units.scss';

.FileInput {
  display: block;

  position: relative;

  width: 100%;
  min-height: 230px;
  border: 1px dashed $grey;

  cursor: pointer;

  * {
    pointer-events: none;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    text-align: center;
    color: $grey;
    fill: $grey-200;

    .text {
      margin-top: $unit-500;
    }

    .FileInputError {
      margin-top: $unit-200;
      color: $red;
    }
  }

  &:hover,
  &.dragover {
    border-color: $blue;

    .content {
      color: $blue;
      fill: $blue;
    }
  }

  &.with-file {
    min-height: auto;
    border: none;

    .content {
      opacity: 0;
      transition: opacity $transition-short;
    }

    &:hover,
    &.dragover {
      .content {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.617815);
        color: $light;
        fill: $light;
      }
    }
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  input {
    display: none;
  }
}
