@import 'styles/base.scss';

$br: $unit-100;
$iconLength: 20px;
$inset: $unit-300;
$maxWidth: 426px;

.Timezone {
  position: relative;
  width: 100%;
}

.TimezoneDropdown {
  width: 100%;
  max-width: $maxWidth;
}

.TimezoneInput {
  color: $grey-700;
  background-color: $white;
  border: 1px solid $white-500;
  box-sizing: border-box;
  padding: $inset;
  padding-right: $iconLength + $inset * 2;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  font-family: $font-primary;
  transition-property: border-color, color;
  transition-duration: $transition-short;
  width: 100%;
  @include te();

  &.isOpen {
    border-top-left-radius: $br;
    border-top-right-radius: $br;
  }

  &:not(.isOpen) {
    border-radius: $br;
  }

  &:focus {
    outline: none;
    border-color: $primary;
    color: $ink;
  }
}

.TimezoneIcon {
  position: absolute;
  top: 50%;
  right: $unit-300;
  margin-top: -($iconLength / 2);
  width: $iconLength;
  height: $iconLength;
  pointer-events: none;
  fill: $grey-300;
}

.TimezoneList {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid $white-300;
  border-top: 0;
  background-color: $white;
  border-bottom-left-radius: $br;
  border-bottom-right-radius: $br;
  z-index: 2;
  box-shadow: 0 0.5rem 1rem rgba(black, 0.1);
}

.TimezoneEmpty {
  padding: $unit-300;
  text-align: center;
}

.TimezoneItem {
  display: flex;
  text-align: left;
  font-size: 14px;
  padding: $unit-300;
  border: 0;
  cursor: pointer;
  text-transform: capitalize;
  background-color: $white;
  transition: background-color $transition-short;

  & + & {
    border-top: 1px solid $white-300;
  }

  &:hover {
    background-color: $white-200;
  }

  &:focus {
    z-index: 1;
  }

  &.isSelected {
    color: map-get($colors, primary);
  }

  &:not(.isSelected) {
    .TimezoneItemArea {
      color: map-get($default, a1);
    }
  }
}

.TimezoneItemArea {
  font-family: monospace;
}

.TimezoneItemBody {
  display: flex;
  flex: 1 1 auto;
  margin-right: $unit-200;

  > span + span {
    margin-left: 1ch;
  }
}
