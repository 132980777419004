@import 'styles/base.scss';

$externalIconLength: 28px;
$inputHeight: 48px;

.EventLocationHeading {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: $unit-200;
}

.EventLocationRow {
  display: flex;

  & + & {
    margin-top: $unit-600;
  }
}

.EventLocationColumn {
  width: 50%;

  &:first-child {
    padding-right: $unit-400 / 2;
  }

  &:last-child:not(:only-child) {
    padding-left: $unit-400 / 2;
  }
}

.EventLocationMapsContainer {
  position: relative;

  input {
    padding-right: $unit-200 * 2 + $externalIconLength;
  }
}

.EventLocationOpen {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: $unit-200 * 2 + $externalIconLength;
  color: #a7b1c7;
  transition: color $transition-short;

  &:active:hover {
    transition: none;
    opacity: 0.5;
  }

  &:hover {
    color: $grey-700;
  }

  > svg {
    width: $externalIconLength;
    height: $externalIconLength;
    position: relative;
    top: 1px;
  }
}
