$transition-short: 0.125s;
$transition-normal: 0.25s;
$transition-long: 0.5s;

$delay-short: $transition-short;
$delay-normal: $transition-normal;
$delay-long: $transition-long;

$swift-in: cubic-bezier(0.1, 1, 0.55, 1);

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.fadeIn {
  opacity: 0;
  animation: $transition-normal forwards fadeIn;
}
