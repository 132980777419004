@import 'styles/base.scss';

$borderWidth: 1px;
$highlightColor: map-get($default, b1);

.Accordion {
  width: 100%;
  border-right: $borderWidth solid $grey-200;
  border-left: $borderWidth solid $grey-200;
  border-bottom: $borderWidth solid $grey-200;
  background-color: $white-100;

  &:last-child {
    border-bottom-right-radius: $unit-100;
    border-bottom-left-radius: $unit-100;
  }

  &:hover {
    background-color: map-get($default, b4);
  }

  &[data-open='true'] {
    border-left: 2px solid $highlightColor;
    background-color: $highlightColor;

    > .Guest {
      background-color: $highlightColor;
      border-bottom: 1px solid $highlightColor;
      color: $white;
      padding-left: $unit-400 - 1px;

      &[data-canceled='true'] .GuestField {
        text-decoration: line-through;
        color: $white-400;
      }
    }

    .NumberOfGuests {
      span {
        border-color: $highlightColor;
        background-color: $white-100;
      }
    }
  }

  &[data-debug='true'] {
    margin: 50px 20px;
  }

  .Content {
    width: 100%;
    display: flex;
    flex-direction: column;

    border-bottom-right-radius: $unit-100;
    border-bottom-left-radius: $unit-100;

    &[data-debug='true'] {
      margin: 50px 20px;
    }

    .Guest {
      padding-left: $unit-400 - 1px;
      cursor: default;
    }
  }
}

.AccordionContent {
  background-color: map-get($default, b4);
}

.AccordionPartyIcon {
  margin-right: 1ch;
}
