@import 'styles/base.scss';

$border-radius: $unit-100;
$padding: $unit-300;
$font-family: $font-primary;
$font-size: 12px;
$font-weight: 600;

@mixin commonButton($bg, $hover, $color: $white-100) {
  background-color: $bg;
  color: $color;
  fill: $color;

  &:enabled,
  &[href] {
    &:hover:not(:active) {
      background-color: $hover;
    }

    &:active:hover {
      color: rgba($color, 0.5);
      fill: rgba($color, 0.5);
    }
  }

  &:disabled {
    color: rgba($color, 0.5);
    fill: rgba($color, 0.5);
  }
}

.Button {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
  border: 1px solid transparent;
  outline: none;
  padding: 0 $padding;
  text-decoration: none;
  font-family: $font-family;
  font-size: $font-size;
  line-height: 1;
  text-transform: uppercase;
  font-weight: $font-weight;
  min-width: 120px;
  height: 44px;
  text-align: center;
  transition-property: opacity, border-color, background-color, color, fill;
  transition-duration: 125ms;
  color: $white-100;
  fill: $white-100;

  &:active:hover {
    transition: none;
  }

  &:enabled {
    cursor: pointer;
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }

  &[data-type='primary'] {
    @include commonButton(
      $bg: map-get($colors, primary),
      $hover: map-get($colors, primary--hover)
    );

    &:disabled,
    &[disabled] {
      background-color: map-get($colors, primary--disabled);
    }
  }

  &[data-type='secondary'] {
    @include commonButton(
      $bg: $white-100,
      $hover: $white-100,
      $color: $blue-800
    );
    border-color: $grey-200;

    .Loader {
      .item {
        background-color: $primary;
      }
    }
  }

  &[data-type='warning'] {
    @include commonButton(
      $bg: map-get($colors, warning),
      $hover: map-get($colors, warning)
    );
  }

  &[data-type='success'] {
    @include commonButton(
      $bg: map-get($colors, success),
      $hover: map-get($colors, success--hover)
    );
  }

  &[data-type='header'] {
    @include commonButton(
      $bg: map-get($colors, secondary),
      $hover: map-get($colors, secondary--hover)
    );
  }

  &[data-type='dark'] {
    @include commonButton(
      $bg: map-get($colors, dark),
      $hover: map-get($colors, dark--hover)
    );
  }

  &[data-type='toolbar'] {
    @include commonButton(
      $bg: map-get($colors, toolbar),
      $hover: map-get($colors, toolbar--hover)
    );
  }

  .Loader {
    .item {
      background-color: $light;
      height: 10px;
      width: 10px;
    }
  }
}
