// Base Typography file
// Project: Cetrez Events

@import '_colors.scss';

// Root typography definitions
$font-primary: 'Montserrat', sans-serif;

body {
  font-family: $font-primary;
  font-size: 16px;
}

// Heading styles
h1,
.h1 {
  font-size: 24px;
  font-weight: 600;
}

h2,
.h2 {
  font-weight: 600;
  font-size: 22px;
}

h3,
.h3 {
  font-weight: 600;
  font-size: 16px;
}

// Paragraph styles
p {
  color: $grey-700;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
}

// Other

label,
.label {
  color: $grey-700;
  font-size: 14px;
}

.tnum {
  font-feature-settings: 'tnum';
}
