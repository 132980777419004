.Modal[data-modal='PreviewSmsModal'] {
  strong {
    font-weight: 700;
  }

  .Separator {
    margin: 20px -16px;
    height: 1px;
    border: 0;
    background-color: #e1e2e3;
  }

  .Message {
    min-height: 100px;
    margin: 10px -16px 0 -16px;
    padding: 16px;
    border: 1px solid #e1e2e3;
    border-radius: 4px;
  }
}
