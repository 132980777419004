@import 'styles/base.scss';

$dateSize: 32px;

.Calendar {
  width: 355px;
  border: 1px solid $grey-100;
  border-radius: 6px;
  background: $white;
  padding-bottom: $unit-200;
  padding: $unit-200;
  z-index: 1000;
  @include bs($black);
}

.WeekDays {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid $white-300;
  padding-bottom: $unit-200;
  margin-bottom: $unit-200;
  font-size: 12px;
  font-weight: 400;
  color: $purple-600;
  text-transform: uppercase;

  .day {
    text-align: center;
    margin: $unit-100 $unit-200;
    width: $dateSize;
    @include te();
  }
}
