@import 'styles/base.scss';

.LocationPicker {
  display: flex;
  flex-direction: column;
  position: relative;

  .LocationInput {
    padding: 0 ($unit-300 * 2 + 20px) 0 $unit-300;
    height: 46px;
    border: 1px solid $white-400;
    border-radius: $unit-100 / 2;
    font-size: 14px;
    outline: none;

    &:focus {
      border-color: $blue;
    }
  }

  .DropDownInner {
    background-color: $white;
    border-left: 1px solid $blue;
    border-right: 1px solid $blue;
    border-bottom: 1px solid $blue;
    border-bottom-right-radius: $unit-100 / 2;
    border-bottom-left-radius: $unit-100 / 2;
    width: 100%;
  }

  .DropDown {
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    justify-content: center;
    top: 100%;
    overflow: hidden;
    z-index: 2;
    padding-bottom: $unit-600;

    &[data-visible='true'] {
      min-height: 50px;
      opacity: 1;
    }

    .Location {
      display: block;
      width: 100%;
      padding: $unit-300;
      border: 0px;
      border-bottom: 1px solid $grey-100;
      text-align: left;
      cursor: pointer;
      line-height: 1.15;

      > b {
        font-weight: 700;
      }

      &:last-child {
        border-bottom: 0px;
      }

      &[data-active='true'] {
        background-color: map-get($default, b4);

        &:focus {
          outline: none;
          background: $blue-700;
          color: $white;
        }
      }

      &:hover {
        background-color: map-get($default, b4);
      }

      &:focus {
        outline: none;
        background-color: map-get($default, b4);
      }
    }
  }
}

.LocationPickerIconLabel {
  position: absolute;
  margin-top: -10px;
  top: 50%;
  right: $unit-300;
  cursor: text;

  > svg {
    fill: map-get($default, a2);
  }
}
