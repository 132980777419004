@import 'styles/base.scss';

.Radiobutton {
  display: flex;
  align-items: center;
  flex-direction: row;
  position: relative;
  margin-bottom: $unit-400;

  &.isDisabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  input {
    opacity: 0;
    z-index: -1;
    position: absolute;
    font-size: 14px;

    &:checked {
      + label .inner {
        opacity: 1;
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $purple-400;
    border-radius: 100%;
    height: 26px;
    width: 26px;
    overflow: hidden;
    background-color: $white-100;
  }

  .inner {
    opacity: 0;
    background: $darkblue-100;
    height: 18px;
    width: 18px;
    border-radius: 100%;
  }

  svg {
    opacity: 0;
    height: auto;
    width: 10px;
    fill: $blue-400;
  }

  span {
    font-size: 14px;
    margin-left: $unit-300;
  }
}
