.scroll_container {
  overflow-y: auto;
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 2px;
}

.scroll_container > li {
  padding: 8px 0;
}
