@import 'styles/base.scss';

.SmsEdit {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  background-color: white;

  .Container {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    padding: 50px 20px 130px 20px;

    opacity: 1;
    transition: opacity $transition-short;

    &.is-loading {
      opacity: 0;
    }
  }

  .InputContainer {
    label {
      font-weight: 600;
      color: $ink;
    }
  }

  .CharacterCount {
    margin-top: $unit-100;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
  }

  .RadioButtons {
    display: flex;

    .Radiobutton {
      margin-right: 50px;

      label {
        font-weight: 600;
        color: $ink;
      }
    }
  }

  .Subtitle {
    margin-top: $unit-600;
    margin-bottom: $unit-300;
  }
}

.SmsEditToolbar {
  display: flex;

  .Button {
    margin-left: 10px;
  }
}

.SmsEditError {
  margin-top: $unit-100;
  font-size: 12px;
  text-align: right;
  color: $warning;
}
