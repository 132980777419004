@import 'styles/base.scss';

$unitLength: 48px;

.GuestToggle {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.GuestToggleCount {
  margin-left: $unit-400;
}

.GuestCount {
  display: flex;
  align-items: row;
  align-items: center;

  svg {
    fill: $grey-400;
  }

  .icon-container {
    height: $unitLength;
    width: $unitLength;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-100;
    border-right: 0px;
    background-color: map-get($default, b4);
  }

  input {
    border: 1px solid $grey-100;
    text-align: center;
    width: $unitLength;
    height: $unitLength;
    outline: none;
    box-sizing: border-box;
    font-size: 14px;
    color: $grey-700;

    &:disabled {
      background: $white-200;
      color: $grey-400;
    }

    &:focus {
      border-color: $blue-700;
    }
  }
}
