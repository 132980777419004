@import 'styles/base.scss';

.Header {
  background: $white-200;
  color: $grey;
  width: 100%;

  .NavContainer {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: space-between;
    min-width: 0;

    .NavItem {
      a {
        position: relative;

        &::after {
          content: '';
          background: $darkblue-400;
          height: 2px;
          width: 0%;
          display: block;
          bottom: -$unit-200;
          position: absolute;
          transition: width $transition-normal ease-in-out $delay-short;
        }

        &:hover {
          color: $darkblue-400;
        }

        &.active {
          color: $darkblue-400;

          &::after {
            width: 100%;
          }
        }
      }
    }
  }

  &[data-dark='true'] {
    background-color: $darkblue-400;
    color: $white-100;

    .NavItem {
      a {
        &:hover {
          color: $white-100;
        }

        &::after {
          content: '';
          background-color: $white-100;
          height: 2px;
          width: 0%;
          display: block;
          bottom: -$unit-200;
          position: absolute;
          transition: width $transition-normal ease-in-out $delay-short;
        }

        &.active {
          color: $white-100;

          &::after {
            width: 100%;
          }
        }
      }
    }

    .isMuted {
      .NavItem {
        a {
          &:not(.active):not(:hover) {
            color: rgba($white-100, 0.25);
          }
        }
      }
    }
  }
}

.HeaderLogo {
  position: relative;
  left: -15px;
  margin-right: 35px;
}
