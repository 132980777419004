@import 'styles/base.scss';

.Thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  min-width: 266px;
  background-color: $white;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;

  img {
    display: none;
  }

  .loader-container,
  .thumb-placeholder {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white-300;

    &.loader-container {
      background: $white-300;
      z-index: 2;
    }

    &.thumb-placeholder {
      opacity: 0;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  &[data-loaded='false'] {
    .loader-container {
      opacity: 1;
    }
  }

  &[data-loaded='true'] {
    .loader-container {
      opacity: 0;
      transition: opacity $transition-normal ease-in-out $delay-short;
    }
  }

  &[data-error='true'] {
    .loader-container {
      opacity: 0;
      transition: opacity $transition-normal ease-in-out $delay-short;
    }

    .thumb-placeholder {
      opacity: 1;
    }
  }
}
