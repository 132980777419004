@import 'styles/base.scss';

.SmsFooter {
  display: flex;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  height: 80px;
  background: #fafafc;
  border: 1px solid #e1e2e3;

  .Content {
    display: flex;
    flex-direction: row;

    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    .Section {
      flex: 1;
      display: flex;
      align-items: center;
      white-space: nowrap;

      &.Recipients {
        position: relative;
        padding-right: $unit-300;

        svg {
          color: $darkblue-100;
        }

        .SmsFooterTitle {
          margin-left: $unit-200;
        }

        &::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;

          display: block;
          height: 20px;
          width: 1px;
          background-color: $ink;

          transform: translateY(-50%);
        }
      }

      &.Scheduled {
        padding-left: $unit-300;
      }
    }
  }
}

.SmsFooterTitle {
  font-size: 18px;
  font-weight: 600;
  color: $ink;
}

.SmsFooterDescription {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 600;
  color: $darkblue-100;
}
