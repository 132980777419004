@import 'styles/base.scss';

.Logo {
  background: $darkblue-400;
  height: 76px;
  min-width: 80px;
  max-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  .bounds {
    width: 48px;
    height: 28px;
  }

  .text {
    fill: $white-100;
  }
}
