@import 'styles/base.scss';

$inputSize: 48px;
$inset: $unit-300;
$extra-inset: $unit-200;
$iconLength: 10px;
$br: $unit-100;

.Picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 148px;
  position: relative;

  .icon {
    height: $inputSize;
    min-width: $inputSize;
    max-width: $inputSize;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey-100;
    border-right: 0;
    border-radius: $br 0 0 $br;
    background-color: map-get($default, b4);

    svg {
      fill: map-get($default, a2);
    }
  }
}

.PickerButton {
  height: $inputSize;
  border: 1px solid $grey-100;
  width: 100%;
  cursor: pointer;
  color: $grey-700;
  transition-property: border-color, color;
  transition-duration: $transition-short;
  padding-right: $inset + $iconLength + $extra-inset;
  padding-left: $inset;
  outline: none;
  text-align: left;
  border-radius: 0 $br $br 0;
  white-space: nowrap;

  .isOpen > &,
  &:focus,
  &:hover {
    border-color: $blue-700;
    color: $black;

    > svg {
      fill: $grey-700;
    }
  }

  > svg {
    position: absolute;
    right: $inset;
    top: 50%;
    margin-top: -($iconLength / 2);
    height: $iconLength;
    width: $iconLength;
    fill: map-get($default, b2);
    transition: fill $transition-short;
  }
}
