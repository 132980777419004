@import 'styles/base.scss';

.EventResult {
  display: flex;
  flex-direction: column;
  padding-bottom: $unit-800;
  flex: 1 1 auto;
  background-color: map-get($default, b4);

  .ActiveEventHeader {
    margin-bottom: $unit-600;
  }
}

.EventResultWrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.EventMark {
  display: inline-block;
  border-radius: 3px;
  background-color: white;
  padding: 0.2em 0.75ch;
  border: 1px solid $grey-200;
  box-shadow: 0 2px 0 rgba($grey-200, 0.5);
  position: relative;
}

.AccordionHeader {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: $unit-500;

  h1 {
    margin-right: $unit-600;
  }
}

.EventResultHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: $unit-300;
}

.EventResultName {
  margin-bottom: $unit-200;
}

.EventResultSection {
  & + & {
    margin-top: $unit-600;
  }
}

.EventResultTime {
  font-feature-settings: none;
  font-size: 14px;
}

.EventResultData {
  margin-right: $unit-300;
}

.EventResultMeta {
  display: flex;
  text-align: center;
  font-size: 14px;
}

.EventResultMetaColumn {
  & + & {
    margin-left: $unit-300;
  }
}

.EventResultMetaNumber {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: $unit-200;
}
