@import 'styles/base.scss';

$activeBorderWidth: $unit-100;
$borderWidth: 1px;
$iconWidth: 14px;
$iconHeight: 8px;
$buttonInset: $unit-300;

.StateToggler {
  font-size: 12px;
  max-width: 330px;
  width: 100%;
}

.StateTogglerInner {
  flex: 1 1 auto;
  position: relative;
  transition: filter $transition-normal;
  filter: drop-shadow(0 3px 3px rgba(black, 0.075));

  &.isOpen,
  &:hover:not(.isDisabled) {
    filter: drop-shadow(0 0 10px rgba(black, 0.1));
    z-index: 1;
  }
}

.StateTogglerButton {
  outline: none;
  text-align: left;
  cursor: pointer;
  width: 100%;
  padding: $buttonInset;
  padding-right: $buttonInset * 2 + $iconWidth;
  border: $borderWidth solid $grey-200;
  background-color: white;
  position: relative;

  &[data-state='DRAFT'] {
    color: map-get($colors, draft);
    background-color: #fcfcfc;
  }

  &[data-state='CLOSED'] {
    color: map-get($colors, closed);
    background-color: #fbfcfd;
  }

  &[data-state='ACTIVE'] {
    color: map-get($colors, active);
    background-color: #f8fdfb;
  }

  &[data-state='FULL'] {
    color: map-get($colors, full);
    background-color: #fbfcfd;
  }

  &:hover,
  &:focus {
    z-index: 1;
  }
}

.StateTogglerToggler {
  border-top-left-radius: $activeBorderWidth;
  border-top-right-radius: $activeBorderWidth;
  border-left: $activeBorderWidth solid;
  transition-property: border-color, color;
  transition-duration: $transition-normal;

  &:disabled {
    cursor: not-allowed;
  }

  &:enabled {
    &[data-state='DRAFT'] {
      border-left-color: #ec644b !important;

      &:hover,
      &:focus {
        border-color: #debbc2;
      }
    }

    &[data-state='CLOSED'] {
      border-left-color: #547497 !important;

      &:hover,
      &:focus {
        border-color: #bac4da;
      }
    }

    &[data-state='ACTIVE'] {
      border-left-color: #2ecc71 !important;

      &:hover,
      &:focus {
        border-color: #85c3ba;
      }
    }
  }

  &:only-child {
    border-bottom-left-radius: $activeBorderWidth;
    border-bottom-right-radius: $activeBorderWidth;
  }
}

.StateTogglerList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 0;
}

.StateTogglerItem {
  margin-top: -$borderWidth;

  &:last-child {
    > .StateTogglerItemButton {
      border-bottom-left-radius: $activeBorderWidth;
      border-bottom-right-radius: $activeBorderWidth;
    }
  }
}

.StateTogglerHeading {
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 0.1ch;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: $unit-100;
  word-break: break-all;
  @include te(); // Text ellipsis
}

.StateTogglerText {
  font-size: 1em;
  line-height: 1.2;
  color: $grey-800;
}

.StateTogglerIcon {
  position: absolute;
  top: 50%;
  right: $buttonInset;
  width: $iconWidth;
  height: $iconHeight;
  margin-top: -($iconHeight / 2);

  &.isOpen {
    transform: scaleY(-1);
  }
}
