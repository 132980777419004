@import 'styles/base.scss';

.Modal[data-modal='ErrorSmsModal'] {
  .Header {
    display: flex;
    align-items: center;

    svg {
      margin-right: 10px;
      color: $warning;
    }
  }

  .container {
    border: 1px solid $warning;
  }
}
