@import 'styles/base.scss';

.EventPlaceholderCard {
  width: 266px;
  margin-bottom: $unit-800;

  .card {
    height: 178px;
    border: 1px dashed $grey-200;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: none;
    width: 100%;
  }
}

.active {
  .EventPlaceholderCard {
    .card {
      height: 258px;
    }
  }
}
