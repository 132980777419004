@import 'styles/base.scss';

$gutterSize: $unit-200;

.SelectableCard {
  width: (100% / 4);
  padding: $gutterSize;
  position: relative;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    cursor: pointer;
  }
}

.SelectableCardButton {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  position: relative;
  background-color: $grey-100;
  transition: opacity $transition-short;
  @include forceRatio(16 10);

  &:enabled:active:hover {
    transition: none;
    opacity: 0.75;
  }
}

.CheckmarkIcon {
  background: $primary;
  position: absolute;
  bottom: $gutterSize;
  right: $gutterSize;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 14px;
    width: 14px;
    fill: $white;
  }
}

.CardIcon {
  // background: $primary;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 50px;
    fill: $white;
  }
}
