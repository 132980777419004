@import 'styles/base.scss';

$sidebar-width: 640px;
$sidebar-offset: calc((100vw - #{$sidebar-width}));
$toggle-width: $unit-500;
$toggle-height: 120px;
$sidebar-bg: map-get($default, b4);

// Transitions
.fade-enter {
  opacity: 0;
  transform: translateY($unit-300);
  z-index: 1;
  position: absolute;
  width: 100%;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity $transition-normal ease-in $transition-normal,
    transform $transition-normal ease-in $transition-normal;
}

.fade-exit {
  transform: translateY(0);
  opacity: 1;
}

.fade-exit.fade-exit-active {
  transform: translateY($unit-300);
  opacity: 0;
  transition: opacity $transition-normal ease-in,
    transform $transition-normal ease-in;
}

// Page
.EventEdit {
  overflow: hidden;
  margin: 0;
  padding: 0;

  .Editor {
    height: calc(100vh - #{$editorHeaderHeight});
    overflow-y: auto;
    scroll-behavior: smooth;
    position: relative;

    @media screen and (max-width: 1280px) {
      display: flex;
      flex-direction: row;
      min-height: calc(100vh - #{$editorHeaderHeight});
      height: 100%;
    }
  }

  .Sidebar {
    width: $sidebar-width;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    padding-bottom: $unit-500;
    background-color: $sidebar-bg;
    transition: transform $transition-normal $swift-in;

    &.is-hidden {
      transform: translateX(-100%) translateX(#{$toggle-width / 2}); // IE11 doesn't support calc() as args to transforms, this is computed similar to a calc() but works in IE11
    }

    &.will-hide {
      // applied/removed on mouseover/mouseleave of toggler
      will-change: transform;
    }

    .Nav {
      padding-left: $unit-500;
      background-color: white;
      @include bs($black);

      .NavItem {
        &:not(:first-child) {
          padding-left: 10px;
        }

        &:not(:last-child) {
          padding-right: 10px;
        }
      }

      // nav link styling
      a {
        color: $grey;
        padding: $unit-400 0;
        transition: opacity $transition-normal ease-in-out;
        position: relative;

        &::after {
          content: '';
          display: block;
          background-color: currentColor;
          height: 2px;
          width: 0;
          position: absolute;
          bottom: 0;
          transition: width $transition-normal ease-in-out $delay-short;
        }

        &.active {
          color: map-get($default, b1);

          &::after {
            width: 100%;
          }
        }
      }
    }

    p {
      margin-bottom: $unit-400;
    }

    .backgroundButton {
      position: absolute;
      top: $unit-200;
      right: $unit-200;
      padding-top: 0;
      padding-bottom: 0;

      > svg {
        margin-right: $unit-200;
      }
    }
  }

  .EditForm {
    background-color: $sidebar-bg;
  }
}

.PreviewContainer {
  display: flex;
  position: fixed;
  top: $editorHeaderHeight;
  right: 0;
  bottom: 0;
  left: $sidebar-width;
  transition: left $transition-normal $swift-in;
  backface-visibility: hidden; // promote to hardware accelerated rendering layer to improve animation performance of `left`

  &.will-expand {
    // applied/removed on mouseover/mouseleave of toggler
    will-change: left;
  }

  &.is-expanded {
    left: $toggle-width / 2;
  }
}

.SidebarToggle {
  position: absolute;
  top: calc(50% - #{$toggle-height / 2});
  left: calc(100% - #{$toggle-width / 2});
  width: $toggle-width;
  height: $toggle-height;
  padding: $unit-300 $unit-200;
  background-color: $sidebar-bg;
  outline: none;
  border: 0;
  border-radius: 0 $unit-100 $unit-100 0;
  cursor: pointer;

  > svg {
    margin-top: $unit-200;
    margin-bottom: $unit-200;
    fill: $blue-300;
    transition: fill $transition-normal;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    width: 2px;
    border-left: 1px solid map-get($default, b2);
    border-right: 1px solid map-get($default, b2);
    margin-left: auto;
    margin-right: auto;
    height: 26px;
    transition: border-color $transition-normal;
  }

  &:hover {
    > svg {
      fill: $blue;
    }

    &::before,
    &::after {
      border-color: map-get($default, a3);
    }
  }

  &:active:hover {
    > svg {
      fill: $blue-600;
      transition: none;
    }

    &::before,
    &::after {
      border-color: map-get($default, a2);
      transition: none;
    }
  }
}
