@import 'styles/base.scss';

$inputSize: 42px;

.DatePicker {
  position: relative;
  width: 100%;
  min-width: 355px;
  outline: none;
  margin-right: $unit-400;

  .Calendar {
    position: absolute;
    margin-top: $unit-200;
    margin-left: auto;
    margin-right: auto;
    top: 100%;
    left: 0;
    right: 0;
  }

  button {
    &:focus {
      outline: none;
    }
  }

  &.is-readonly {
    opacity: 0.6;
    pointer-events: none;
  }

  &.is-errored {
    .PickerButton,
    .icon {
      border-color: $warning;
    }
  }
}
