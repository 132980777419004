@import 'styles/base.scss';

$circleLength: 48px;

.ExtendedLandingPagesList {
  .CreatorButton {
    position: relative;
    margin-bottom: 0;

    &:before {
      content: '';
      display: block;
      background: $grey-100;
      position: absolute;
      top: 50%;
      width: calc(50% - 40px);
      height: 1px;
    }

    &:after {
      content: '';
      display: block;
      background: $grey-100;
      position: absolute;
      right: 0;
      top: 50%;
      width: calc(50% - 40px);
      height: 1px;
    }

    button {
      background-color: $green-400;
      border-radius: 50%;
      height: $circleLength;
      width: $circleLength;
      border: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      cursor: pointer;

      &:hover {
        background-color: $green-300;
      }
    }

    svg {
      height: 16px;
      width: auto;
      fill: $white-100;
    }
  }

  .Page {
    display: flex;
    align-items: center;

    padding: $unit-300 0;
    border-bottom: 1px solid $grey-100;

    .DragIcon {
      opacity: 0.4;
      margin-right: $unit-300;
    }

    .Text {
      flex: 1;

      input {
        max-height: $unit-500;
        padding: $unit-200 $unit-300;
      }
    }

    .Button {
      min-width: auto;
      height: auto;
      padding: $unit-200;
    }

    &:last-child {
      border-bottom: none;
    }

    &[data-dragging='true'] {
      border-bottom: none;
    }
  }
}
