@import 'styles/base.scss';

$inset: $unit-300;
$fontSize: 14px;
$lineHeight: 1.2;
$minRows: 3;
$maxRows: 20;
$borderWidth: 1px;

.Textarea {
  background-color: $white-100;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: $borderWidth solid $grey-100;
  border-radius: $unit-100 / 2;
  resize: none;
  font-size: $fontSize;
  font-family: inherit;
  outline: none;
  line-height: $lineHeight;
  min-height: ceil(
    $fontSize * $lineHeight * $minRows + $inset * 2 + $borderWidth * 2
  );
  max-height: ceil(
    $fontSize * $lineHeight * $maxRows + $inset * 2 + $borderWidth * 2
  );
  padding: $inset;
  transition: border 0.125s ease-in-out;

  &:focus {
    border: 1px solid $blue-700;
  }
}
