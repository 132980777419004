.CloseButton {
  background-color: #224b78;

  &[disabled] {
    opacity: 0.5;
  }
}

.ResetChangesButton {
  min-width: 44px;
  padding: 12px;
}
