@import 'styles/base.scss';

.Countdown {
  padding: $unit-300;
  background-color: map-get($colors, warning);
  opacity: 0.9;
  color: white;
  border-radius: 3px;
  text-align: center;
  font-weight: 600;
  @include bs(rgba(black, 0.25));
}

.CountdownLabel {
  display: block;
  line-height: 1.15;
  font-size: 10px;
  margin-bottom: $unit-200;
}

.CountdownNumber {
  display: block;
}
