@import 'styles/base.scss';

.Modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  min-width: 100%;
  background: rgba($black, 0.5);
  overflow: auto;
  max-height: 100dvh;

  .wrapper {
    height: 100%;
    min-height: 100vh;
    flex: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: '';
      min-height: inherit;
      font-size: 0;
      display: block;
    }
  }

  .container {
    max-height: 90%;
    position: relative;
    min-width: 450px;
    max-width: 480px;
    flex: 1 1 auto;
    width: 100%;
    margin: $unit-200 0;
    border-radius: 4px;
    background-color: $white-100;
    display: flex;
    flex-direction: column;

    &[data-size='medium'] {
      min-width: 520px;
      max-width: 540px;
    }

    &[data-size='large'] {
      min-width: 580px;
      max-width: 620px;
    }

    > div {
      width: 100%;
    }

    &.Scrollable {
      .Content {
        overflow: hidden;
      }
    }
  }

  .Header {
    font-size: 18px;
    font-weight: 700;
    color: $black;
    padding: $unit-600 $unit-600 $unit-500;
    width: 100%;
    text-transform: uppercase;
    background-color: $white-100;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .Content {
    width: 100%;
    height: 100%;
    padding: 0 $unit-600 $unit-600;
    background-color: $white-100;

    /* Content expands to fits whole available height */
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    &.fluid {
      padding: 0;
    }
  }

  .Actions {
    width: 100%;
    min-height: 76px;
    border-top: 1px solid map-get($default, b3);
    background-color: map-get($default, b4);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: $unit-300;

    &.ActionsFull {
      justify-content: space-between;
    }

    button {
      &:not(:last-child) {
        margin-right: $unit-200;
      }
    }
    .ModalRightActions {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }
}

.disableScroll {
  overflow: hidden;
}
