@import 'styles/base.scss';

.tab-navigation {
  margin-top: -$unit-400;
  padding-left: $unit-600;
  padding-right: $unit-600;
}

.tabs-wrapper {
  display: flex;

  & .tab-content {
    width: 100%;
    padding: $unit-300 $unit-600;
    box-shadow: inset 0px 2px 14px 0px rgba(0, 0, 0, 0.11);
    background: #ffffff;
    display: block; /* undo "display: none;" */
    visibility: hidden;
    margin-right: -100%;

    &.active {
      visibility: visible;
    }
  }
}

.no-backgrounds {
  padding: $unit-200 0;
}

[data-modal='PickBackgroundModal'] {
  .container {
    max-width: 720px !important;
  }

  .cards {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -$unit-200;
  }
}
