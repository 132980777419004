@import 'styles/base.scss';

.Preview {
  display: flex;
  width: 100%;
  background-color: $white-300;

  &[data-fixed='true'] {
    height: calc(100vh - #{$editorHeaderHeight});
    position: fixed;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 50vw;

    @media screen and (max-width: 1280px) {
      position: relative;
      width: 640px;
      height: 100%;
      min-height: 100vh;
    }
  }

  &[data-loaded='true'] {
    .loading {
      opacity: 0;
      pointer-events: none;
      transition: opacity $transition-long ease-in-out;
    }
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white-300;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  iframe {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
}
