@import 'styles/base.scss';

.Toggle {
  $size: 32px;
  $offset: $unit-200;

  width: $size * 2;
  height: $size * 1;

  label {
    background: $grey-100;
    height: 100%;
    width: 100%;
    display: block;
    border-radius: $size;
    cursor: pointer;
    transition: background $transition-normal;

    .t {
      @include bs($black);
      border-radius: $size;
      height: $size - $offset;
      width: $size - $offset;
      background: $white;
      display: block;
      position: relative;
      top: $offset / 2;
      left: $offset / 2;
      transition: transform $transition-normal $swift-in;
    }

    input:checked {
      + label {
        .t {
          transform: translateX($size);
        }
      }
    }
  }

  &[data-size='large'] {
    $size: 32px;
    $offset: $unit-200;

    width: $size * 2;
    height: $size * 1;

    .t {
      border-radius: $size;
      height: $size - $offset;
      width: $size - $offset;
      top: $offset / 2;
      left: $offset / 2;
    }

    input:checked {
      + label {
        .t {
          transform: translateX($size);
        }
      }
    }
  }

  &[data-size='medium'] {
    $size: 24px;
    $offset: $unit-200;

    width: $size * 2;
    height: $size * 1;

    .t {
      border-radius: $size;
      height: $size - $offset;
      width: $size - $offset;
      top: $offset / 2;
      left: $offset / 2;
    }
  }

  input {
    display: none;

    &:checked {
      + label {
        background: $success;
        .t {
          transform: translateX($size);
        }
      }
    }
  }
}
