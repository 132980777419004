@import 'styles/_colors.scss';
@import 'styles/_units.scss';

.EmbedVideoModal {
  .Header {
    padding-bottom: $unit-300;
  }

  .EmbedVideoModalDescription {
    margin-bottom: $unit-400;
  }

  .Textarea {
    min-height: 200px;
  }
}

.EmbedVideoModalError {
  margin-top: $unit-200;
  color: $red;
}
