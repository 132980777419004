@import 'styles/base.scss';

$shadowColor: #848484;

.EventPages {
  $menu-width: 300px;
  height: calc(100vh - #{$editorHeaderHeight});
  background: $white;
  display: flex;
  overflow: hidden;

  // Styles
  .Sidebar {
    $offset: calc(50vw - ((#{$container-width} - #{$unit-600} * 2) / 2));
    position: relative;
    background: $white;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .SidebarHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: $unit-200;
      padding-left: $offset;
      background-color: $light;
      border-bottom: 1px solid map-get($default, b2);
      border-right: 1px solid $grey-100;
      @include bs-large($shadowColor);

      h1 {
        font-size: 15px;
        font-weight: bold;
      }

      .Button {
        min-width: 44px;
        padding: 12px;
      }
    }

    .HorizontalMenu {
      flex: 1;
      width: calc(#{$menu-width} + #{$offset});
      border-right: 1px solid $grey-100;
      padding-top: $unit-300;
      padding-left: $offset;

      .MenuItem {
        display: block;
        padding: $unit-300 $unit-400 $unit-300 $unit-200;
        position: relative;
        text-decoration: none;
        color: inherit;
        overflow-x: hidden;

        &::after {
          display: block;
          content: '';
          height: 100%;
          background: $darkblue;
          width: $unit-100;
          position: absolute;
          top: 0;
          opacity: 0;
          transform: translateX($unit-100);
          right: 0;
          transition: transform 0.25s ease-out, opacity 0.25s ease-out;
        }

        &.active {
          color: $darkblue;
          font-weight: 600;
          &::after {
            transform: translateX(0);
            opacity: 1;
            transition: transform 0.25s ease-out 0.25s,
              opacity 0.25s ease-out 0.25s;
          }
        }
      }
    }
  }

  .PagesContainer {
    background: $white-200;
    flex: 1 0 auto;
    overflow-y: auto;

    .track {
      display: flex;
      align-items: stretch;

      min-height: calc(100vh - #{$editorHeaderHeight});
      height: auto;

      padding: 0;
      position: relative;
    }
  }
}
