@import 'styles/base.scss';

$borderWidth: 1px;

.Guest {
  display: flex;
  flex-direction: row;
  min-height: $unit-500 - $borderWidth * 2;
  padding-left: $unit-400;
  padding-right: $unit-400;
  box-sizing: content-box;

  .Accordion > & {
    cursor: pointer;
  }

  .fields,
  .meta {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .fields {
    width: calc(100% - 340px);
  }

  .meta {
    width: 340px;

    .date {
      width: 170px;
    }
  }

  &[data-canceled='true'] {
    .GuestField {
      text-decoration: line-through;
      color: $grey-500;
    }
  }
}

.GuestTitle {
  padding: $unit-300 $unit-400;
  padding-left: calc(#{$unit-400} - 1px);
  box-sizing: content-box;
  min-height: $unit-500;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid $grey-200;

  .fields {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 70%;
  }

  .label {
    flex: 1 1 100%;
    padding-right: $unit-300;
    font-weight: 600;
  }
}

.GuestField {
  flex: 1 1 100%;
  padding-top: $unit-400 - 1px;
  padding-bottom: $unit-400 - 1px;
  padding-right: $unit-300;
  @include te(); // Text ellipsis
}

.NumberOfGuests {
  width: 80px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $unit-500;
    width: $unit-500;
    border: 1px solid $grey-100;
    border-radius: 50%;
    line-height: normal;
    font-weight: 600;
    color: $blue-800;
    margin-left: auto;
  }
}

.PartyCheckinStatus {
  display: flex;
  align-items: center;
  width: 90px;

  span {
    display: block;
    margin-left: $unit-300;
  }

  svg {
    fill: $success;
  }
}

.IconCheckmark {
  fill: $success;
}
