@import 'styles/base.scss';

$themeColor: #950014;

[data-modal='PreviewEmailModal'] {
  .Content {
    padding: 0;
    height: 650px;
    max-height: 80vh;
  }
}

.PreviewEmailModalIframe {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  transition: opacity $transition-long;

  &:not(.isLoaded) {
    opacity: 0;
  }
}

.PreviewEmailModalLoader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
