@import 'styles/base.scss';

.PartyActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $unit-300 $unit-400;
  padding-left: calc(#{$unit-400} - 1px);
  border-bottom: 1px solid $grey-200;

  .Button {
    cursor: pointer;
  }
}

.GuestActions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;

  svg {
    fill: $grey-400;

    &[data-color='success'] {
      fill: $success;
    }
    &[data-color='warning'] {
      fill: $warning;
    }
  }

  .actions {
    width: 150px;
    display: flex;
    justify-content: flex-end;
  }
}

.ActionsButton {
  border: 0;
  margin-left: $unit-400;
  cursor: pointer;

  &.is-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
