@import 'styles/base.scss';

$shadowColor: #848484;

.ActiveEventHeader {
  width: 100%;

  .ActiveEventHeaderInfo {
    margin-top: -1px;
    border-left: none;
    border-right: none;
    text-align: center;
    @include bs-large($shadowColor);

    .EventMark {
      font-variant-caps: all-small-caps;
    }
  }

  .ActiveEventHeaderPrimary {
    padding-top: $unit-500;
    padding-bottom: $unit-500;
    background-color: $light;
    border-bottom: 1px solid map-get($default, b2);
    position: relative;
    @include bs-large($shadowColor);
  }

  .timeArea {
    display: flex;
  }

  .timeItem {
    font-size: 14px;

    + .timeItem {
      margin-left: $unit-300;
    }
  }

  .timeLabel {
    text-transform: uppercase;
    font-weight: 700;
    margin-right: 0.5ch;
  }

  .ActiveEventHeaderColumn {
    min-width: 0;
    flex: 1 1 33.333%;

    &:not(:first-child) {
      padding-left: $unit-400;
    }

    &:not(:last-child) {
      padding-right: $unit-400;
    }

    &:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }

  .bigNumbers {
    display: flex;
    justify-content: center;

    .BigNumber {
      flex: 1 1 33.333%;
      padding-left: $unit-200;
      padding-right: $unit-200;

      &:not(:first-child) {
        border-left: 1px solid $grey-200;
      }

      &:last-child {
        border-right: 0;
      }

      .number {
        text-align: center;
        font-size: 40px;
        font-weight: 500;
        line-height: 1;
      }

      .label {
        display: block;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 14px;
        color: rgba($text-color, 0.8);
        position: relative;
        top: -1px; // visually align to other column text, required because these labels have a smaller font-size
      }

      &.isMuted {
        .number,
        .label {
          color: rgba($text-color, 0.4);
        }
      }
    }
  }

  .title {
    @include te;
    max-width: 100%;
    line-height: 1.1667;
    padding-bottom: $unit-400;
  }

  .actions {
    margin-top: $unit-600;

    .Button {
      margin-left: $unit-200;
      padding-top: 0;
      padding-bottom: 0;

      > svg {
        margin-right: 1ch;
      }
    }
  }

  h3 {
    color: $grey-400;
  }
}

.ActiveEventHeaderActions {
  display: flex;
}
