@import 'styles/base.scss';

$insetX: $unit-600;
$insetY: $unit-500;

.SettingsModule {
  background-color: $white;
  border-radius: $unit-100;
  border: 1px solid $grey-100;
  margin-bottom: $insetY;
  @include bs($black);

  &[data-size='small'] {
    width: calc(30% - (#{$unit-400} / 2));
  }

  &[data-size='large'] {
    width: calc(70% - (#{$unit-400} / 2));
  }
}

.SettingsModuleInset {
  padding: $insetY $insetX;
}

.SettingsModuleHeaderInner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > .content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.SettingsModuleDescription {
  max-width: 70ch;
}

.SettingsModuleContent {
  &:not(:first-child) {
    border-top: 1px solid $grey-100;
  }
}

.SettingsModuleSpacer {
  align-self: stretch;
  border-left: 1px solid $grey-100;
  margin-left: $insetX;
  padding-left: $insetX;
  padding-top: $insetY;
  padding-bottom: $insetY;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error {
  color: red;
  font-weight: bold;
}

.error-input {
  border: 1px solid red !important;
}
