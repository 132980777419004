@import 'styles/base.scss';

.ExtendedLandingPage {
  position: relative;
  width: 100%;

  &[data-loaded='true'] {
    .loading {
      opacity: 0;
      pointer-events: none;
      transition: opacity $transition-long ease-in-out;
    }
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $white-200;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SaveButton {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}
