@import 'styles/base.scss';

// Set the animationTime
$animationDuration: 0.9s;
$size: 6px;
$speed: 2s;
$offset: $speed / 4;

.Loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;

  &[data-size='small'] {
    $size: $unit-100;

    .item {
      width: $size;
      height: $size;
      margin: $size / 4;
    }
  }
  &[data-size='medium'] {
    $size: $unit-200;

    .item {
      width: $size;
      height: $size;
      margin: $size / 4;
    }
  }

  .item {
    border-radius: 1px;
    $speed: 2s;
    $offset: $speed / 8;
    width: $size;
    height: $size;
    background: $primary;
    margin: $size / 4;
    transform: scaleY(0);
    animation: pulse $speed infinite ease-in-out;

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) {
        animation-delay: $offset * $i;
      }
    }
  }
}
@keyframes pulse {
  0% {
    transform: scaleY(0);
    opacity: 0;
  }

  50% {
    transform: scaleY(3);
    opacity: 1;
  }

  100% {
    transform: scaleY(0);
    opacity: 0;
  }
}
