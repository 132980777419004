@import 'styles/base.scss';

.SmsTable {
  width: 100%;

  th {
    padding: $unit-200 $unit-300;
    font-weight: 700;
    text-align: left;
  }

  td {
    padding: $unit-300;
    text-align: left;
    vertical-align: middle;

    &.nowrap {
      white-space: nowrap;
    }

    &.right {
      text-align: right;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: rgba(216, 216, 216, 0.2);
    }

    tr:nth-child(even) {
      background-color: $light;
    }
  }

  .NoResult {
    border: 1px dashed #bfbfbf;

    td {
      text-align: center;
    }
  }
}

.SmsTableButton {
  border: none;
  outline: none;
  color: #8a9cb0;
  fill: currentColor;
  cursor: pointer;

  &:hover:not(:active) {
    color: rgba(#8a9cb0, 0.5);
  }
}

.SmsStatus {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: $unit-200;
  border-radius: 50%;

  &--draft {
    background-color: #297ad1;
  }

  &--sent {
    background-color: #56d793;
  }

  &--scheduled {
    background-color: #f99215;
  }
}

.pointer {
  cursor: pointer;
}
