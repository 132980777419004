@import 'styles/base.scss';

$columns: 4;
$gutter: $unit-600;

.OverviewGridHeader {
  margin-bottom: $gutter;
  position: relative;
}

.OverviewGridChildren {
  position: absolute;
  right: 0;
  top: -50%;
}

.OverviewGridHeading {
  text-transform: capitalize;
}

.OverviewGridList {
  display: flex;
  flex-wrap: wrap;
  margin-left: -($gutter / 2);
  margin-right: -($gutter / 2);
}

.OverviewGridItem {
  width: (100% / $columns);
  padding-left: $gutter / 2;
  padding-right: $gutter / 2;

  &:nth-child(n + #{$columns + 1}) {
    margin-top: $gutter;
  }
}
