@import 'styles/base.scss';

$inset: $unit-300;
$labelWidth: 48px;

.Search {
  width: 280px;
  height: 46px;
  position: relative;

  input {
    width: 100%;
    height: 100%;
    font-family: inherit;
    font-size: inherit;
    outline: none;
    border: 1px solid map-get($default, a3);
    padding: $unit-200 $inset $unit-200 $labelWidth;
    border-radius: $unit-100;
    font-weight: 500;
    font-size: 14px;
    -webkit-appearance: none;

    &::placeholder {
      color: map-get($default, a1);
    }

    &[data-hidden='true'] {
      opacity: 0;
      transition: opacity $transition-short;
      pointer-events: none;
    }

    &:focus {
      opacity: 1;
      pointer-events: all;
      border-color: $blue-700;

      + label {
        color: $blue-700;
        .SearchIcon {
          fill: $blue-700;
        }
      }
    }
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: $labelWidth;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    fill: map-get($default, a1);
  }
}
