@import 'styles/base.scss';

.Tooltip {
  background-color: $black;
  border: 1px solid $white;
  border-radius: 4px;
  padding: $unit-300 $unit-400;
  color: $white;
  position: relative;
  z-index: 2;
  display: block;
  white-space: nowrap;
  font-weight: 500;

  &:after {
    box-sizing: border-box;
    content: '';

    height: 18px;
    width: 18px;
    border-left: 1px solid $white;
    border-top: 1px solid $white;
    background: $black;
    position: absolute;
    top: -10px;
    right: $unit-400;
    transform: rotate(45deg);
  }
}
