@import 'styles/base.scss';

.NotificationsWrapper {
  margin: 0 0 $unit-600;
  margin-left: -$unit-400;
  width: calc(100% + #{$unit-400});
}

.Notifications {
  color: $white;
  border-radius: $unit-100;
  width: 100%;
  margin: 0 0 $unit-300;

  .NotificationInfo {
    background-color: map-get($colors, focus);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-items: baseline;
    font-size: 14px;
  }

  .NotificationWarning {
    background-color: map-get($colors, warning);
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-items: baseline;
    font-size: 14px;
  }

  .NotificationIcon {
    flex: 1;
    text-align: left;
    padding: $unit-300 $unit-300;
    max-width: $unit-300 * 2 + 14px;
  }

  .NotificationMessage {
    flex: 2;
    text-align: left;
  }

  .NotificationDismiss {
    flex: 1;
    cursor: pointer;
    text-align: right;
    padding: $unit-300 $unit-300;
    max-width: $unit-300 * 2 + 14px;
  }
}
