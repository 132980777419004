@import 'styles/base.scss';

$buttonSpacing: 12px;

.EventToolbar {
  display: flex;
  flex: 0 0 auto;

  .HomeIcon {
    margin-right: $unit-200;
  }

  .Button {
    min-width: 44px;
    padding: $unit-400 / 2;

    &:not(:first-child) {
      margin-left: $buttonSpacing;
    }

    &.HomeButton,
    &.SaveButton {
      min-width: 112px;
    }

    &.DuplicateButton {
      > svg {
        position: relative;
        left: 1px;
      }
    }
  }

  .UndoButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .Button {
      background-color: transparent;
      border-color: transparent;
      margin-left: 0;
      transition: opacity $transition-short;

      &:enabled:active:hover {
        transition: none;
        opacity: 0.5;
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    .RedoUndoIcon {
      &[data-direction='left'] {
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: 'FlipH';
      }
    }
  }
}

.EventToolbarTooltipContainer {
  position: absolute;
  right: 0;
  top: $unit-700;
}

.EventToolbarGroup {
  margin-left: $buttonSpacing;
  position: relative;
}

.EventToolbarSaveArea {
  display: flex;

  &.isHidden {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }
}

.EventToolbarHomeArea {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.EventToolbarHomeSeparator {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  padding-top: $unit-200;
  padding-bottom: $unit-200;

  &::before {
    content: '';
    display: block;
    width: 1px;
    background-color: rgba(white, 0.15);
  }
}
